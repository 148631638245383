import React from 'react';
import ReactDOM from 'react-dom';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const App = () => {
  const style = { fontSize: '0.25rem' };
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{ fontSize: '0.3rem' }}>Welcome to Crm h5 web server</h1>
      </header>
      <p style={style}>
        海报：<a href="/poster/index.html">poster/index.html</a>
      </p>
      <p style={style}>
        经纪人卡片：
        <a href="/forwarderCard/index.html">forwarderCard/index.html</a>
      </p>
      <p style={style}>
        bos显示楼书：
        <a href="/buildingBook/index.html#/1794/BUILDING_BOOK">
          buildingBook/index.html
        </a>
      </p>
      <p style={style}>
        新楼书详情：
        <a href="/channelDetail/index.html?id=138&sId=211">
          channelDetail/index.html
        </a>
      </p>
      <p style={style}>
        推广集合：
        <a href="/aggregatesChannel/index.html?id=31">
          aggregatesChannel/index.html
        </a>
      </p>
      <p style={style}>
        经纪人微店：
        <a href="/forwarderShop/index.html">forwarderShop/index.html</a>
      </p>
      <p style={style}>
        楼书库：
        <a href="/buildingBookRecommendList/index.html">
          buildingBookRecommendList/index.html
        </a>
      </p>
      <p style={style}>
        销控图：
        <a href="/salesControlChart/index.html">salesControlChart/index.html</a>
      </p>
      <p style={style}>
        vrtest：
        <a href="/vrtest/index.html">forwarderShop/index.html</a>
      </p>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
